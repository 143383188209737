<template>
  <v-select
    :value="value"
    :items="options"
    :loading="loading"
    :menu-props="menuProps"
    :return-object="returnObject"
    label="Clinical Codes"
    multiple
    outlined
    clearable
    hide-details
    @click:clear="$emit('change', null)"
    @change="$emit('change', $event)"
  >
    <template v-if="value && value.length" v-slot:append>
      <v-badge :content="value.length" primary inline />
    </template>

    <template #prepend-item>
      <div class="clinical-codes-multiselect__search">
        <v-list-item>
          <v-list-item-content class="pt-2 pb-0">
            <v-text-field
              placeholder="Search code or description"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              clearable
              hide-details
              @input="onSearch"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </div>
    </template>

    <template v-slot:selection="data">
      <v-chip
        v-if="data.index < 2"
        close
        small
        label
        @click:close="onRemove(data.item)"
      >
        {{ data.item.text }}
      </v-chip>
      <span v-if="data.index === 2">...</span>
    </template>
  </v-select>
</template>

<script>
import { debounce } from "lodash";
import { getClinicalCodes } from "@/services/clinicalCodes";

const menuProps = {
  bottom: true,
  offsetY: true,
  left: true,
};

export default {
  props: {
    value: {
      type: Array,
      default: () => null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      clinicalCodes: [],
      menuProps,
      itemsCount: 0,
    };
  },

  computed: {
    options() {
      if (!this.clinicalCodes.length) return [];
      return this.clinicalCodes.map((code) => ({
        value: code._id,
        text: code.label ? `${code.code} - ${code.label}` : code.code,
      }));
    },
    selectedCodes() {
      if (!this.value || !this.clinicalCodes.length) return [];
      return this.value.map((code) =>
        this.clinicalCodes.find(
          ({ _id }) => _id === code || _id === code?.value
        )
      );
    },
  },

  async mounted() {
    await this.initialize();
  },

  methods: {
    async initialize() {
      const query = {};
      if (this.value && this.value?.length) {
        query.filter = {
          _id: {
            $in: this.returnObject
              ? this.value.map((code) => code.value)
              : this.value,
          },
        };
        query.pageSize = 50;
      }
      await this.fetch(query);
      if (this.returnObject && this.value) {
        if (!this.options.length) return this.$emit("change", null);
        const optionsMap = this.options.reduce((acc, option) => {
          acc[option.value] = option;
          return acc;
        }, {});
        this.$emit(
          "change",
          this.value.map(({ value }) => optionsMap[value])
        );
      }
    },
    async fetch(query) {
      try {
        this.loading = true;
        const { data } = await getClinicalCodes(query || {});
        this.clinicalCodes = [...data, ...this.selectedCodes];
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
      }
    },
    onSearch: debounce(function (search) {
      const query = {};
      if (search?.length) {
        const regex = { $regex: search, $options: "i" };
        query.filter = {
          $or: [{ code: regex }, { label: regex }],
        };
      }
      this.fetch(query);
    }, 800),
    onRemove({ value }) {
      const codes = this.value.filter((code) =>
        this.returnObject ? value !== code?.value : value !== code
      );
      this.$emit("change", codes);
    },
  },
};
</script>
